<template>
	<v-row>
		<DialogDashboards :dialog="dialog" />
		<v-col cols="12" md="12">
			<v-card>
				<v-card-text>
					<v-form>
						<v-row :class="dashboard.dtultimaatualizacao ? 'mb-n13' : 'mb-n10'">
							<v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtinicio"
									label="Data inicial"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col class="d-none d-md-block px-1">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtfim"
									label="Data final"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
								<v-sheet class="text-center" height="100%">
									<v-row class="pa-3">
										<v-col cols="6">
											<InputDatePicker
												:disabled="carregando"
												v-model="busca.dtinicio"
												label="Data inicial"
												:outlined="true"
												:dense="true"
											/>
										</v-col>
										<v-col cols="6">
											<InputDatePicker
												:disabled="carregando"
												v-model="busca.dtfim"
												label="Data final"
												:outlined="true"
												:dense="true"
											/>
										</v-col>
										<v-col cols="6">
											<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
										</v-col>
										<v-col cols="6">
											<v-btn text class="mt-n3" color="primary" @click="get(), sheet = !sheet">Filtrar</v-btn>
										</v-col>
									</v-row>
								</v-sheet>
							</v-bottom-sheet>
							<v-col class="px-1 pl-md-1 pr-lg-3 d-flex">
								<v-autocomplete
									class="d-inline-flex d-md-none"
									:items="filiaisFiltro"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfilial"
									append-outer-icon="mdi-menu"
									@click:append-outer="sheet = !sheet"
								></v-autocomplete>
								<v-autocomplete
									class="d-none d-md-block"
									:items="filiaisFiltro"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfilial"
								></v-autocomplete>
								<v-btn
									:disabled="carregando"
									class="ml-2 mt-1"
									color="primary"
									elevation="0"
									fab
									x-small
									@click="get()"
								>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<template v-if="!carregando">
					<span
						v-if="dashboard.dtultimaatualizacao"
						class="text-caption text--disabled pl-4"
					>Atualizado em: {{dashboard.dtultimaatualizacao}}</span>
				</template>
				<div v-else class="mt-n2 pb-2">
					<v-skeleton-loader class="ml-4" width="40%" height="12" type="image"></v-skeleton-loader>
				</div>
			</v-card>
		</v-col>
		<v-col cols="12" md="12">
			<v-row class="pa-2">
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Valor total em aguardando">
						<template v-if="dashboard.valaguardando">{{dashboard.valaguardando | formataDinheiro }}</template>
						<template v-else>{{0 | formataDinheiro }}</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Custo atual do aguardando">
						<template v-if="dashboard.valcusto">{{dashboard.valcusto | formataDinheiro }}</template>
						<template v-else>{{0 | formataDinheiro }}</template>
						<MenuInfo texto="Valor de custo com base no valor de última compra." />
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Margem de lucro">
						<template v-if="dashboard.margem">{{dashboard.margem.toFixed(2)}} %</template>
						<template v-else>0.00 %</template>
						<MenuInfo texto="Margem de lucro com base no valor de última compra mais impostos." />
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Notas em aguardando e venda futura">
						<template v-if="dashboard.qtdpedidos">{{dashboard.qtdpedidos}}</template>
						<template v-else>0</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Média de tempo em aguardando">
						<template
							v-if="dashboard.mediadias"
						>{{dashboard.mediadias}} dias / {{(dashboard.mediadias / 30).toFixed(1)}} meses</template>
						<template v-else>0</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Notas em 30 dias">
						<!--ajustar a nome do json para menos de 30 dias -->
						<template v-if="dashboard.qtdnoprazo">{{dashboard.qtdmes}} ({{dashboard.permes ? dashboard.permes.toFixed(2) : 0}} %)</template>
						<template v-else>0</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Notas menos de 180 dias">
						<template
							v-if="dashboard.qtdnoprazo"
						>{{dashboard.qtdnoprazo}} ({{dashboard.pervencer.toFixed(2)}} %)</template>
						<template v-else>0</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Notas a mais de 180 dias">
						<template
							v-if="dashboard.qtdvencida"
						>{{dashboard.qtdvencida}} ({{dashboard.pervencido.toFixed(2)}} %)</template>
						<template v-else>0</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Aguardando vendido à vista / Margem à vista">
						<template
							v-if="dashboard.valoravista"
						>{{dashboard.valoravista | formataDinheiro}} / {{dashboard.margemvista.toFixed(2)}} %</template>
						<template v-else>{{0 | formataDinheiro}}</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Aguardando vendido à prazo / Margem à prazo">
						<template
							v-if="dashboard.valoraprazo"
						>{{dashboard.valoraprazo | formataDinheiro}} / {{dashboard.margemprazo.toFixed(2)}} %</template>
						<template v-else>{{0 | formataDinheiro}}</template>
					</CardDashboard>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" md="12">
			<v-card>
				<v-card-title class="d-flex col-12 text-truncate">
					Análise do estoque em relação ao aguardando
					<v-spacer />
					<v-btn
						:disabled="carregando"
						class="px-2 mb-1 ml-auto mt-2 mr-2"
						color="primary"
						elevation="0"
						small
						@click="abrirDialog(
                        dashboard.aguardandoskuproduto.filter(u => u.status == 'ATENDE').map(v => {return {idfilial:v.idfilial, codigo: v.codigo, produto:v.produto} }), 
                        {
                            idfilial: {nome: 'Filial', tipo: 'texto'},
                            codigo: {nome: 'Cod. Produto', tipo: 'texto'},
                            produto: {nome: 'Produto', tipo: 'texto'},
                        },
                        'SKUS que o estoque atende'
                    )"
					>
						<v-icon size="18">mdi-magnify-plus-outline</v-icon>ATENDE
					</v-btn>
					<v-btn
						:disabled="carregando"
						class="px-2 mb-1 ml-auto mt-2 mr-2"
						color="primary"
						elevation="0"
						small
						@click="abrirDialog(
                        dashboard.aguardandoskuproduto.filter(u => u.status == 'PARCIAL').map(v => {return {idfilial:v.idfilial, codigo: v.codigo, produto:v.produto} }), 
                        {
                            idfilial: {nome: 'Filial', tipo: 'texto'},
                            codigo: {nome: 'Cod. Produto', tipo: 'texto'},
                            produto: {nome: 'Produto', tipo: 'texto'},
                        },
                        'SKUS que atende parcial'
                    )"
					>
						<v-icon size="18">mdi-magnify-plus-outline</v-icon>PARCIAL
					</v-btn>
					<v-btn
						:disabled="carregando"
						class="px-2 mb-1 ml-auto mt-2 mr-2"
						color="primary"
						elevation="0"
						small
						@click="abrirDialog(
                        dashboard.aguardandoskuproduto.filter(u => u.status == 'SEMESTOQUE').map(v => {return {idfilial:v.idfilial, codigo: v.codigo, produto:v.produto} }), 
                        {
                            idfilial: {nome: 'Filial', tipo: 'texto'},
                            codigo: {nome: 'Cod. Produto', tipo: 'texto'},
                            produto: {nome: 'Produto', tipo: 'texto'},
                        },
                        'SKUS que não atende'
                    )"
					>
						<v-icon size="18">mdi-magnify-plus-outline</v-icon>NÃO ATENDE
					</v-btn>
				</v-card-title>
				<v-card-text v-if="!carregando">
					<GraficoBarDashboard
						v-if="dashboard.perestoqueatendido || dashboard.perestoqueparcial || dashboard.perestoquezerado"
						:dataSeries="[{name: 'Porcentagem', data: [dashboard.perestoqueatendido, dashboard.perestoqueparcial, dashboard.perestoquezerado]}]"
						:categories="['SKUS que o estoque atende', 'SKUS que atende parcial', 'SKUS que não atende']"
						:horizontal="false"
						:money="false"
						:porcentagem="true"
						height="350"
						:distributed="true"
						:dataLabels="{ position : 'top', offsetY : -20 }"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
				<v-card-text v-else>
					<v-skeleton-loader class="mx-auto" width="100%" height="350" type="image, image"></v-skeleton-loader>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="12">
			<v-expansion-panels>
				<v-expansion-panel active-class="primary white--text">
					<v-expansion-panel-header class="font-weight-bold text-h6">
						Valor em aguardando por filial
						<template v-slot:actions>
							<v-icon color="white" class="mr-n6">$expand</v-icon>
							<v-icon>$expand</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content v-if="dashboard.aguardandofilial">
						<v-card flat rounded="0">
							<v-card-text v-if="!carregando">
								<!-- Trocar tabela -->
								<v-simple-table dense>
									<thead>
										<tr>
											<th>Filial</th>
											<th>Valor do aguardando a época da venda</th>
											<th>Valor do aguardando com preço de venda atualizado</th>
											<th>Custo do apoio à decisão atualizado</th>
											<th>Lucro atualizado com base no apoio à decisão</th>
											<th>Margem atualizada com base no apoio à decisão (%)</th>
											<th>% de participação sobre o aguardando da rede</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(v, i) in dashboard.aguardandofilial" :key="i">
											<td class="text-truncate">{{v.filial}}</td>
											<td>{{v.valvendido | formataDinheiro}}</td>
											<td>{{v.valvendavalatual | formataDinheiro}}</td>
											<td>{{v.custoatual | formataDinheiro}}</td>
											<td>{{v.lucrocustoatual | formataDinheiro}}</td>
											<td>{{v.margemcustoatual.toFixed(2)}}</td>
											<td>{{v.percentualfilial.toFixed(2)}}</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<td class="font-weight-bold">Total</td>
											<td
												class="font-weight-bold"
											>{{dashboard.aguardandofilial.map(v => {return v.valvendido}).reduce((a, v) => a + v) | formataDinheiro}}</td>
											<td
												class="font-weight-bold"
											>{{dashboard.aguardandofilial.map(v => {return v.valvendavalatual}).reduce((a, v) => a + v) | formataDinheiro}}</td>
											<td
												class="font-weight-bold"
											>{{dashboard.aguardandofilial.map(v => {return v.custoatual}).reduce((a, v) => a + v) | formataDinheiro}}</td>
											<td
												class="font-weight-bold"
											>{{dashboard.aguardandofilial.map(v => {return v.lucrocustoatual}).reduce((a, v) => a + v) | formataDinheiro}}</td>
											<td class="font-weight-bold">{{dashboard.margem.toFixed(2)}}</td>
											<td
												class="font-weight-bold"
											>{{dashboard.aguardandofilial.map(v => {return v.percentualfilial}).reduce((a, v) => a + v).toFixed(1)}} %</td>
										</tr>
									</tfoot>
								</v-simple-table>
							</v-card-text>
							<v-card-text v-else>
								<v-skeleton-loader class="mx-auto" width="100%" height="200" type="table-tbody"></v-skeleton-loader>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel active-class="primary white--text">
					<v-expansion-panel-header class="font-weight-bold text-h6">
						Valor em aguardando por cliente
						<template v-slot:actions>
							<v-icon color="white" class="mr-n6">$expand</v-icon>
							<v-icon>$expand</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content v-if="dashboard.aguardandocliente">
						<v-card flat rounded="0">
							<v-card-text v-if="!carregando">
								<v-data-table
									mobile-breakpoint="10"
									dense
									:headers="[
                                        { text: 'Cliente', value: 'cliente', sortable: false },
                                        { text: 'Valor do aguardando a época da venda', value: 'valvendido', sortable: false },
                                        { text: 'Valor do aguardando com preço de venda atualizado', value: 'valvendavalatual', sortable: false },
                                        { text: 'Custo do apoio à decisão atualizado', value: 'custoatual', sortable: false },
                                        { text: 'Lucro atualizado com base no apoio à decisão', value: 'lucrocustoatual', sortable: false },
                                        { text: 'Margem atualizada com base no apoio à decisão (%)', value: 'margemcustoatual'},
                                    ]"
									:items="dashboard.aguardandocliente.map((v) => {
                                        const obj = {}
                                        obj.codigo = v.codigo
                                        obj.cliente = v.cliente
                                        obj.valvendido = parseFloat(v.valvendido).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.valvendavalatual = parseFloat(v.valvendavalatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.custoatual = parseFloat(v.custoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.lucrocustoatual = parseFloat(v.lucrocustoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.lucrocustoatual = parseFloat(v.lucrocustoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.lucrocustoatual = parseFloat(v.lucrocustoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.margemcustoatual = `${v.margemcustoatual.toFixed(2)}` 
                                        return obj   
                                    })"
									:items-per-page="10"
									class="elevation-0"
								>
									<template v-slot:item="{ item }">
										<tr
											@click="abrirDialog(
                                        dashboard.aguardandoprodutocliente.filter(v=> v.cliente == item.codigo), 
                                        {
                                            produto: {nome: 'Produtos', tipo: 'texto'},
                                            qtdentregar: {nome: 'Quantidade', tipo: 'texto', class:'text-center'},
                                        },
                                        'Produtos e quantidade'
                                    )"
										>
											<td>{{item.cliente}}</td>
											<td>{{item.valvendido}}</td>
											<td>{{item.valvendavalatual}}</td>
											<td>{{item.custoatual}}</td>
											<td>{{item.lucrocustoatual}}</td>
											<td>{{item.margemcustoatual}}</td>
										</tr>
									</template>
								</v-data-table>
							</v-card-text>
							<v-card-text v-else>
								<v-skeleton-loader class="mx-auto" width="100%" height="200" type="table-tbody"></v-skeleton-loader>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel active-class="primary white--text">
					<v-expansion-panel-header class="font-weight-bold text-h6">
						Valor em aguardando por produto
						<template v-slot:actions>
							<v-icon color="white" class="mr-n6">$expand</v-icon>
							<v-icon>$expand</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content v-if="dashboard.aguardandoproduto">
						<v-card flat rounded="0">
							<v-card-text v-if="!carregando">
								<v-data-table
									dense
									:headers="[
                                        { text: 'Produto', value: 'produto', sortable: false },
                                        { text: 'Quantidade', value: 'quantidade', sortable: false },
                                        { text: 'Valor do aguardando a época da venda', value: 'valvendido', sortable: false },
                                        { text: 'Valor do aguardando com preço de venda atualizado', value: 'valvendavalatual', sortable: false },
                                        { text: 'Custo do apoio à decisão atualizado', value: 'custoatual', sortable: false },
                                        { text: 'Lucro atualizado com base no apoio à decisão', value: 'lucrocustoatual', sortable: false },
                                        { text: 'Margem atualizada com base no apoio à decisão (%)', value: 'margemcustoatual'},
                                    ]"
									:items="dashboard.aguardandoproduto.map((v) => {
                                        const obj = {}
                                        obj.produto = v.produto
                                        obj.quantidade = v.quantidade
                                        obj.valvendido = parseFloat(v.valvendido).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.valvendavalatual = parseFloat(v.valvendavalatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.custoatual = parseFloat(v.custoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.lucrocustoatual = parseFloat(v.lucrocustoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.margemcustoatual = `${v.margemcustoatual.toFixed(2)}` 
                                        return obj   
                                    })"
									sort-by="margemcustoatual"
									:items-per-page="10"
									class="elevation-0"
								>
									<template v-slot:item="{ item }">
										<tr>
											<td>{{item.produto}}</td>
											<td class="text-center">{{item.quantidade}}</td>
											<td>{{item.valvendido}}</td>
											<td>{{item.valvendavalatual}}</td>
											<td>{{item.custoatual}}</td>
											<td>{{item.lucrocustoatual}}</td>
											<td>{{item.margemcustoatual}}</td>
										</tr>
									</template>
								</v-data-table>
							</v-card-text>
							<v-card-text v-else>
								<v-skeleton-loader class="mx-auto" width="100%" height="200" type="table-tbody"></v-skeleton-loader>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel active-class="primary white--text">
					<v-expansion-panel-header class="font-weight-bold text-h6">
						Valor em aguardando por seção
						<template v-slot:actions>
							<v-icon color="white" class="mr-n6">$expand</v-icon>
							<v-icon>$expand</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content v-if="dashboard.aguardandosecao">
						<v-card flat rounded="0">
							<v-card-text v-if="!carregando">
								<v-data-table
									dense
									:headers="[
                                        { text: 'Seção', value: 'secao', sortable: false },
                                        { text: 'Valor do aguardando a época da venda', value: 'valvendido', sortable: false },
                                        { text: 'Valor do aguardando com preço de venda atualizado', value: 'valvendavalatual', sortable: false },
                                        { text: 'Custo do apoio à decisão atualizado', value: 'custoatual', sortable: false },
                                        { text: 'Lucro atualizado com base no apoio à decisão', value: 'lucrocustoatual', sortable: false },
                                        { text: 'Margem atualizada com base no apoio à decisão (%)', value: 'margemcustoatual'},
                                    ]"
									:items="dashboard.aguardandosecao.map((v) => {
                                        const obj = {}
                                        obj.secao = v.secao
                                        obj.valvendido = parseFloat(v.valvendido).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.valvendavalatual = parseFloat(v.valvendavalatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.custoatual = parseFloat(v.custoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.lucrocustoatual = parseFloat(v.lucrocustoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.margemcustoatual = `${v.margemcustoatual}` 
                                        return obj   
                                    })"
									:items-per-page="10"
									class="elevation-0"
								></v-data-table>
							</v-card-text>
							<v-card-text v-else>
								<v-skeleton-loader class="mx-auto" width="100%" height="200" type="table-tbody"></v-skeleton-loader>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel active-class="primary white--text">
					<v-expansion-panel-header class="font-weight-bold text-h6">
						Valor em aguardando por fornecedor
						<template v-slot:actions>
							<v-icon color="white" class="mr-n6">$expand</v-icon>
							<v-icon>$expand</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content v-if="dashboard.aguardandofornecedor">
						<v-card flat rounded="0">
							<v-card-text v-if="!carregando">
								<v-data-table
									dense
									:headers="[
                                        { text: 'Fornecedor', value: 'fabricante', sortable: false },
                                        { text: 'Valor do aguardando a época da venda', value: 'valvendido', sortable: false },
                                        { text: 'Valor do aguardando com preço de venda atualizado', value: 'valvendavalatual', sortable: false },
                                        { text: 'Custo do apoio à decisão atualizado', value: 'custoatual', sortable: false },
                                        { text: 'Lucro atualizado com base no apoio à decisão', value: 'lucrocustoatual', sortable: false },
                                        { text: 'Margem atualizada com base no apoio à decisão (%)', value: 'margemcustoatual'},
                                    ]"
									:items="dashboard.aguardandofornecedor.map((v) => {
                                        const obj = {}
                                        obj.fabricante = v.fabricante
                                        obj.valvendido = parseFloat(v.valvendido).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.valvendavalatual = parseFloat(v.valvendavalatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.custoatual = parseFloat(v.custoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.lucrocustoatual = parseFloat(v.lucrocustoatual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        obj.margemcustoatual = `${v.margemcustoatual}` 
                                        return obj   
                                    })"
									:items-per-page="10"
									class="elevation-0"
								></v-data-table>
							</v-card-text>
							<v-card-text v-else>
								<v-skeleton-loader class="mx-auto" width="100%" height="200" type="table-tbody"></v-skeleton-loader>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";
import MenuInfo from "../Widgets/MenuInfo";
import DialogDashboards from "../Widgets/DialogDashboards";

export default {
	name: "DashboardAguardando",
	mixins: [mixinFilial],
	components: {
		InputDatePicker,
		CardDashboard,
		GraficoBarDashboard,
		MenuInfo,
		DialogDashboards,
	},
	data: () => ({
		sheet: false,
		carregando: false,
		dashboard: {},
		dialog: { status: false, dados: [], header: {} },
		busca: {
			dtinicio: `1990-01-01`,
			dtfim: `2090-01-01`,
			idfilial: 0,
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		get() {
			this.dashboard = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}aguardando/listar`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idfilial: this.busca.idfilial,
				})
				.then((res) => {
					this.dashboard = res.data;
					this.carregando = false;
				});
		},
		async init() {
			this.busca.idfilial = this.usuario.idfilial;
			await this.get();
		},
		abrirDialog(dados, header, title) {
			this.dialog.status = true;
			this.dialog.dados = dados;
			this.dialog.header = header;
			this.dialog.title = title;
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>